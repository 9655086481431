<template>
  <div>
    <div class="mask"></div>
    <div class="navbar">
      <div class="navbar__main">
        <div class="navbar__logo">
          <RouterLink to="/"><img src="@/assets/navbar/logo.png" alt=""></RouterLink>
        </div>
        <div class="navbar__btn" @click="handleToggleNavbar">
          <img
            v-if="isShowNavbar"
            class="close"
            src="@/assets/navbar/close.png"
            alt="">
          <img
            v-else
            class="more"
            src="@/assets/navbar/more.png"
            alt="">
        </div>
      </div>
      <div class="navbar__navbar" :class="{close: !isShowNavbar}">
        <div
          v-for="(item, index) of list"
          class="nav-bar__navbar__item"
          :class="{
            active: index === activeParentIndex
          }"
          @click="handleSelectActive(item, index)"
          :key="item.title">
          <div class="title">
            {{ item.title }}
            <template v-if="item.sublist">
              <img
                v-if="(index === activeParentIndex)"
                src="@/assets/navbar/active-arrow.png"
                alt="" />
              <img
                v-else
                src="@/assets/navbar/arrow.png"
                alt="" />
            </template>
          </div>
          <template v-if="item.sublist">
            <div class="sub-navbar">
              <div
                v-for="(subItem, subIndex) in item.sublist"
                class="sub-navbar__item"
                :class="{
                  active: subIndex === activeIndex
                }"
                :key="subItem.title">
                <RouterLink :to="subItem.link">{{ subItem.title }}</RouterLink>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isShowNavbar: false,
      activeParentIndex: 0,
      activeIndex: 0,
      list: [
        {
          title: '首页',
          link: '/'
        },
        {
          title: '产品',
          sublist: [
            {
              title: 'iGuard网页防篡改系统',
              link: '/product/iguard'
            },
            {
              title: 'iWall WEB应用防火墙',
              link: '/product/iwall'
            },
            {
              title: 'iFlow WEB应用热补丁网关',
              link: '/product/iflow'
            },
            {
              title: 'iMon 天存睿眼',
              link: '/product/imon'
            }
          ]
        },
        {
          title: '漏洞报告',
          link: '/vulnerability-report/list'
        },
        {
          title: '解决方案',
          sublist: [
            {
              title: '政府政务',
              link: '/solution/government-affairs'
            },
            {
              title: '金融机构',
              link: '/solution/finance'
            },
            {
              title: '央企国企',
              link: '/solution/operator'
            },
            {
              title: '企业用户',
              link: '/solution/industry-institutions'
            }
          ]
        },
        {
          title: '资讯中心',
          link: '/information'
        },
        {
          title: '关于天存',
          sublist: [
            {
              title: '天存简介',
              link: '/intro'
            },
            {
              title: '公司动态',
              link: '/dynamic'
            },
            {
              title: '联系我们',
              link: '/contact-us'
            },
            {
              title: '加入我们',
              link: '/join-us'
            }
          ]
        }
      ]
    }
  },
  mounted () {
    window.addEventListener('click', e => {
      const navbar = document.querySelector('.navbar')
      if (!navbar.contains(e.target)) {
        if (this.isShowNavbar) {
          this.isShowNavbar = false
        }
      }
    })
    window.addEventListener('scroll', () => {
      if (this.isShowNavbar) {
        this.isShowNavbar = !this.isShowNavbar
      }
    })
    const activeParentIndex = this.list.findIndex(v => {
      if (!v.sublist) return v.link === this.$route.path
      return v.sublist.find(v => v.link === this.$route.path)
    })
    this.activeParentIndex = activeParentIndex

    const activeItem = this.list[activeParentIndex]
    if (!activeItem.sublist) return
    this.activeIndex = activeItem.sublist.findIndex(v => v.link === this.$route.path)
  },
  methods: {
    handleSelectActive (item, index) {
      if (!item.sublist) {
        this.$router.push(item.link)
        return
      }
      this.activeParentIndex = index
      this.activeIndex = -1
    },
    handleToggleNavbar () {
      this.isShowNavbar = !this.isShowNavbar
    }
  }
}
</script>
<style lang="scss" scoped>
.navbar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: px2rem(236);
  z-index: 99999999;
}
.mask {
  height: 236px;
}
.navbar__main {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 px2rem(100);
  background-color: #fff;
  border-bottom: 1px solid rgba(195, 195, 195, 1);
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.16);
  z-index: 1000;
}
.navbar__logo {
  width: px2rem(376.97);
  height: px2rem(84.65);
  font-size: 0;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
.navbar__btn {
  font-size: 0;
  padding: px2rem(50);
  cursor: pointer;

  .close {
    width: px2rem(57.45);
  }
  .more {
    width: px2rem(57.45);
  }
}
.navbar__navbar {
  position: absolute;
  left: 0;
  top: px2rem(236);
  width: 100%;
  transform: translateY(0);
  transition: transform .3s;
  background-color: #fff;
  z-index: 0;

  &.close {
    transform: translateY(-100%);
  }
}
.nav-bar__navbar__item {
  display: flex;
  align-items: center;
  width: 484px;
  height: 178px;
  padding: 0 69px 0 128px;
  font-weight: 500;
  border-right: 1px solid #b7b7b7;

  // &.active {
  //   background-color: #f2f2f2;

  //   .title {
  //     font-weight: 700;
  //     color: $themeColor;
  //   }
  // }

  &.active {
    background-color: #f2f2f2;

    .title {
      font-weight: 700;
      color: $themeColor;
    }

    .sub-navbar {
      display: block;
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: px2rem(46);
    font-weight: 500;
    letter-spacing: px2rem(10);
    color: rgba(102, 102, 102, 1);

    .arrow {
      width: 24px;
    }
  }

  .sub-navbar {
    display: none;
    position: absolute;
    left: 484px;
    top: 0;
    width: calc(100% - 484px);
    height: 100%;
  }

  .sub-navbar__item {
    display: flex;
    align-items: center;
    padding-left: px2rem(80);
    height: px2rem(180);
    font-size: px2rem(50);
    font-weight: 400;
    color: rgba(102, 102, 102, 1);

    &.active {
      font-weight: 500;
      background-color: #f2f2f2;

      a {
        color: $themeColor;
      }
    }
  }
}
</style>
