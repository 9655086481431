import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { List } from 'vant'

import NavBar from '@/components/nav-bar'
import Footer from '@/components/footer'
import Media from '@/components/media'

// 公用样式
import '@/scss/index.scss'

Vue.component('NavBar', NavBar)
Vue.component('Footer', Footer)
Vue.component('Media', Media)
Vue.use(List)

Vue.config.productionTip = false
new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
