import Vue from 'vue'
import VueRouter from 'vue-router'

const EmptyComponent = { render (h) { return h('router-view') } }

/* 首页 */ const Home = () => import(/* webpackChunkName: "home" */ '@/views/home')
/* 产品-iguard */ const iGuard = () => import(/* webpackChunkName: "product-iguard" */ '@/views/product/iguard')
/* 产品-iwall */ const iWall = () => import(/* webpackChunkName: "product-iwall" */ '@/views/product/iwall')
/* 产品-iflow */ const iFlow = () => import(/* webpackChunkName: "product-iflow" */ '@/views/product/iflow')
/* 产品-imon */ const iMon = () => import(/* webpackChunkName: "product-imon" */ '@/views/product/imon')

/* 解决方案-政府事务 */ const GovernmentAffairs = () => import(/* webpackChunkName: "solution-government-affairs" */ '@/views/solution/government-affairs')
/* 解决方案-金融 */ const Finance = () => import(/* webpackChunkName: "solution-finance" */ '@/views/solution/finance')
/* 解决方案-运营商 */ const Operator = () => import(/* webpackChunkName: "solution-operator" */ '@/views/solution/operator')
/* 解决方案-行业机构 */ const IndustryInstitutions = () => import(/* webpackChunkName: "solution-industry-institutions" */ '@/views/solution/industry-institutions')

/* 漏洞报告-列表 */ const VulnerabilityReportList = () => import(/* webpackChunkName: "vulnerability-report-list" */ '@/views/vulnerability-report/list')
/* 漏洞报告-详情 */ const VulnerabilityReportDetail = () => import(/* webpackChunkName: "vulnerability-report-detail" */ '@/views/vulnerability-report/detail')

/* 媒体中心-资讯 */ const Information = () => import(/* webpackChunkName: "information" */ '@/views/information')
/* 媒体中心-分享 */ const Share = () => import(/* webpackChunkName: "share" */ '@/views/share')

/* 天存简介 */ const Intro = () => import(/* webpackChunkName: "intro" */ '@/views/intro')
/* 公司动态 */ const Dynamic = () => import(/* webpackChunkName: "dynamic" */ '@/views/dynamic')
/* 加入我们 */ const JoinUs = () => import(/* webpackChunkName: "join-us" */ '@/views/join-us')
/* 联系我们 */ const ContactUs = () => import(/* webpackChunkName: "contact-us" */ '@/views/contact-us')

/* 隐私政策 */ const PrivacyPolicy = () => import(/* webpackChunkName: "privacy-policy" */ '@/views/privacy-policy')
/* 法律声明 */ const LegalStatement = () => import(/* webpackChunkName: "legal-statement" */ '@/views/legal-statement')

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '天存信息'
    }
  },
  {
    path: '/product',
    name: 'Product',
    component: EmptyComponent,
    redirect: '/product/iguard',
    children: [
      {
        path: '/product/iguard',
        name: 'iGuard',
        component: iGuard,
        meta: {
          title: 'iGuard'
        }
      },
      {
        path: '/product/iwall',
        name: 'iWall',
        component: iWall,
        meta: {
          title: 'iWall'
        }
      },
      {
        path: '/product/iflow',
        name: 'iFlow',
        component: iFlow,
        meta: {
          title: 'iFlow'
        }
      },
      {
        path: '/product/imon',
        name: 'iMon',
        component: iMon,
        meta: {
          title: 'iMon'
        }
      }
    ]
  },
  {
    path: '/solution',
    name: 'Solution',
    component: EmptyComponent,
    redirect: '/solution/government-affairs',
    children: [
      {
        path: '/solution/government-affairs',
        name: 'GovernmentAffairs',
        component: GovernmentAffairs,
        meta: {
          title: '政府事务'
        }
      },
      {
        path: '/solution/finance',
        name: 'Finance',
        component: Finance,
        meta: {
          title: '金融'
        }
      },
      {
        path: '/solution/operator',
        name: 'Operator',
        component: Operator,
        meta: {
          title: '央企国企'
        }
      },
      {
        path: '/solution/industry-institutions',
        name: 'IndustryInstitutions',
        component: IndustryInstitutions,
        meta: {
          title: '企业用户'
        }
      }
    ]
  },
  {

    path: '/vulnerability-report',
    name: 'VulnerabilityReport',
    component: EmptyComponent,
    redirect: '/vulnerability-report/list',
    children: [
      {
        path: '/vulnerability-report/list',
        name: 'VulnerabilityReportList',
        component: VulnerabilityReportList,
        meta: {
          title: '漏洞报告'
        }
      },
      {
        path: '/vulnerability-report/detail/:id',
        name: 'VulnerabilityReportDetail',
        component: VulnerabilityReportDetail,
        meta: {
          title: '漏洞详情'
        }
      }
    ]
  },
  {
    path: '/information',
    name: 'Information',
    component: Information,
    meta: {
      title: '天存资讯'
    }
  },
  {
    path: '/share',
    name: 'Share',
    component: Share,
    meta: {
      title: '技术分享'
    }
  },
  {
    path: '/intro',
    name: 'Intro',
    component: Intro,
    meta: {
      title: '天存简介'
    }
  },
  {
    path: '/dynamic',
    name: 'Dynamic',
    component: Dynamic,
    meta: {
      title: '公司动态'
    }
  },
  {
    path: '/join-us',
    name: 'JoinUs',
    component: JoinUs,
    meta: {
      title: '加入我们'
    }
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs,
    meta: {
      title: '联系我们'
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      title: '隐私政策'
    }
  },
  {
    path: '/legal-statement',
    name: 'LegalStatement',
    component: LegalStatement,
    meta: {
      title: '法律声明'
    }
  }
]

const router = new VueRouter({
  // mode: 'history', // request service support
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    // 页面默认滚动到顶部
    return { x: 0, y: 0 }
  },
  routes
})

export default router
