<template>
  <KeepAlive>
    <slot v-if="screen.type === 'pc'"></slot>
    <slot name="mobile" v-else></slot>
  </KeepAlive>
</template>
<script>
export default {
  data () {
    return {
      screen: {
        type: 'pc',
        width: window.innerWidth
      }
    }
  },
  created () {
    this.initComponent()
    window.addEventListener('resize', () => {
      this.initComponent()
    })
  },
  methods: {
    initComponent () {
      this.screen.type = window.innerWidth > 750 ? 'pc' : 'mobile'
      this.screen.width = window.innerWidth
      this.$emit('resize', this.screen)
    }
  }
}
</script>
