<template>
  <Media>
    <Pc />

    <template v-slot:mobile>
      <Mobile />
    </template>
  </Media>
</template>
<script>
import Pc from './pc'
import Mobile from './mobile'

export default {
  components: {
    Pc,
    Mobile
  }
}
</script>
