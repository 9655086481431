<template>
  <div
      class="footer"
      :class="{
      gray: grayTheme
    }">
    <div class="footer__main">
      <Media>
        <div class="footer__navigation">
          <div class="footer__information">
            <img :src="footerInfoPNG" alt="">
          </div>
          <div class="footer__navigation__item">
            <div class="title">产品</div>
            <div class="content">
              <div class="content__item">
                <RouterLink to="/product/iguard">iGuard 网页防篡改系统</RouterLink>
              </div>
              <div class="content__item">
                <RouterLink to="/product/iwall">iWall WEB应用防火墙</RouterLink>
              </div>
              <div class="content__item">
                <RouterLink to="/product/iflow">iFlow WEB应用热补丁网关</RouterLink>
              </div>
              <div class="content__item">
                <RouterLink to="/product/imon">iMon 天存睿眼</RouterLink>
              </div>
            </div>
          </div>
          <div class="footer__navigation__item">
            <div class="title">漏洞报告</div>
            <div class="content">
              <div class="content__item">
                <RouterLink to="/vulnerability-report/list">漏洞报告</RouterLink>
              </div>
            </div>
          </div>
          <div class="footer__navigation__item">
            <div class="title">解决方案</div>
            <div class="content">
              <div class="content__item">
                <RouterLink to="/solution/government-affairs">政府政务</RouterLink>
              </div>
              <div class="content__item">
                <RouterLink to="/solution/finance">金融机构</RouterLink>
              </div>
              <div class="content__item">
                <RouterLink to="/solution/operator">央企国企</RouterLink>
              </div>
              <div class="content__item">
                <RouterLink to="/solution/industry-institutions">企业用户</RouterLink>
              </div>
            </div>
          </div>
          <div class="footer__navigation__item">
            <div class="title">资讯中心</div>
            <div class="content">
              <div class="content__item">
                <RouterLink to="/information">资讯中心</RouterLink>
              </div>
            </div>
          </div>
          <div class="footer__navigation__item">
            <div class="title">关于天存</div>
            <div class="content">
              <div class="content__item">
                <RouterLink to="/intro">天存简介</RouterLink>
              </div>
              <div class="content__item">
                <RouterLink to="/dynamic">公司动态</RouterLink>
              </div>
              <div class="content__item">
                <RouterLink to="/contact-us">联系我们</RouterLink>
              </div>
              <div class="content__item">
                <RouterLink to="/join-us">加入我们</RouterLink>
              </div>
            </div>
          </div>
        </div>

        <template v-slot:mobile>
          <div class="footer__mobile">
            <img
                class="logo"
                :src="logoPNG"
                alt="">
            <div class="title">
              <p>了解更多产品信息</p>
              <p>请联系我们</p>
            </div>
            <img
                class="tel"
                :src="telPNG"
                alt="">
            <div class="links">
              <p @click="$router.push('/privacy-policy')">隐私政策</p>
              <p>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</p>
              <p @click="$router.push('/legal-statement')">法律声明</p>
              <p>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</p>
              <p @click="$router.push('/intro')">关于天存</p>
            </div>
            <div class="copyright">
              <p>版权所有 © 上海天存信息技术有限公司 2003-2023</p>
              <p>Copyright © 2003-2023 SHANGHAI TERCEL INFO TECH CO., LTD.</p>
              <p>沪ICP备05056605号 沪公网安备 31011502008076号</p>
            </div>
          </div>
        </template>
      </Media>
    </div>
    <div class="footer__bottom">版权所有&nbsp;©&nbsp;上海天存信息技术有限公司&nbsp;2003-2023&nbsp;|&nbsp;<RouterLink
        to="/privacy-policy">隐私政策
    </RouterLink>&nbsp;|&nbsp;<RouterLink to="/legal-statement">法律声明</RouterLink>&nbsp;|&nbsp;<RouterLink
        to="/contact-us">联系我们
    </RouterLink>&nbsp;|&nbsp;<RouterLink to="/intro">关于我们</RouterLink>&nbsp;|&nbsp;
      <a href="http://beian.miit.gov.cn/" target="_blank">沪ICP备05056605号</a>&nbsp;
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502008076" target="_blank">沪公网安备&nbsp;31011502008076号</a>
    </div>
  </div>
</template>
<script>
import FooterInfoGray from '@/assets/footer_info-gray.png'
import FooterInfo from '@/assets/footer_info.png'

import FooterLogo from '@/assets/footer/logo.png'
import FooterGreyLogo from '@/assets/footer/logo-grey.png'

import FooterTel from '@/assets/footer/tel.png'
import FooterGreyTel from '@/assets/footer/tel-grey.png'

export default {
  props: {
    grayTheme: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    footerInfoPNG() {
      return this.grayTheme ? FooterInfoGray : FooterInfo
    },
    logoPNG() {
      return this.grayTheme ? FooterGreyLogo : FooterLogo
    },
    telPNG() {
      return this.grayTheme ? FooterGreyTel : FooterTel
    }
  }
}
</script>
<style lang="scss" scoped>
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FD8808;
  padding: px2rem(100) 0 px2rem(20);

  &.gray {
    background: rgba(242, 242, 242, 1);

    .footer__navigation__item,
    .footer__bottom,
    a {
      color: rgba(102, 102, 102, 1);
    }

    @media screen and (max-width: 750px) {
      .footer__mobile .title,
      .footer__mobile .links,
      .footer__mobile .copyright {
        color: rgba(102, 102, 102, 1);
      }
    }
  }

  a {
    color: #fff;
  }

  @media screen and (max-width: 1500px) {
    padding: px2rem(100) px2rem(20) px2rem(20);
  }

  @media screen and (max-width: 750px) {
    padding: 185px 126px 67px;
  }
}

.footer__main {
  display: flex;
  width: px2rem(1480);
  max-width: px2rem(1480);
  margin-bottom: px2rem(50);

  @media screen and (max-width: 1500px) {
    width: 100%;
    margin: 0 px2rem(20);
  }

  @media screen and (max-width: 750px) {
    margin: 0;
    justify-content: center;
  }
}

.footer__mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .logo {
    width: 376.23px;
  }

  .title {
    font-size: 46px;
    color: #FFFFFF;
    line-height: 69px;
    text-align: center;
    margin: 60px 0 64px;
  }

  .tel {
    width: 609.08px;
  }

  .links {
    display: flex;
    font-size: 44px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 75px;
    margin: 183px 0 174px;
  }

  .copyright {
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 42px;
  }
}

.footer__information {
  width: px2rem(183);
  margin-left: px2rem(75);
  margin-right: px2rem(50);

  img {
    width: 90%;
  }
}

.footer__navigation {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.footer__navigation__item {
  font-size: px2rem(14);
  color: #fff;
  margin-right: px2rem(75);

  .title {
    font-weight: 700;
    padding-bottom: px2rem(38);
  }

  .content__item {
    font-weight: 400;

    &:nth-child(n+2) {
      padding-top: px2rem(12);
    }
  }
}

.footer__bottom {
  font-size: px2rem(12);
  color: #FFFFFF;
  text-align: center;

  @media screen and (max-width: 750px) {
    display: none;
  }
}
</style>
